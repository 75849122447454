import { FC, useEffect, useState } from "react";
import { IMiniChatController } from "./indexModel";
import MiniChat from ".";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getReplyMessage, setReplyMessage } from "../../../../../../store/internal";

const MiniChatController: FC<IMiniChatController> = (props) => {
    const { t } = useTranslation();
    const [showChat, setShowChat] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    const replyMessage = useSelector(getReplyMessage)
    const dispatch = useDispatch();


    useEffect(() => {
        const height = showChat ? '480px' : '70px'; // futuramente, deixar a altura configurável pelo cliente
        const width = showChat ? '350px' : '70px'; // futuramente, deixar a largura configurável pelo cliente
        if (window.parent) {
            window.parent.postMessage({ type: 'RESIZE_IFRAME', height, width }, '*');
        }
    }, [showChat]);

    const handleChatToggle = () => {
        setIsAnimating(true);
        if (showChat) {
            setShowChat(false);
            setIsAnimating(false);
        } else {
            setShowChat(true);
            setTimeout(() => {
                setIsAnimating(false);
            }, 300); // Aguardar a animação de entrada
        }
    };

    useEffect(() => {
        dispatch(setReplyMessage(null))
    }, []);


    const formatReplyMessage = (message: string, replyMessage) => {
        if (message) {
            const slicedMessage = message.slice(0, 300);
            const tempDiv = document.createElement("div");
            tempDiv.innerHTML = slicedMessage;
            const decodedMessage = tempDiv.innerText;

            const cleanedMessage = decodedMessage
                .replace(/\n/g, " ")       // Remove quebras de linha
                .replace(/\s+/g, " ")     // Substitui múltiplos espaços por um espaço único
                .trim();                  // Remove espaços no início e no final

            if (cleanedMessage.length === 0) {
                if (replyMessage.attachments.length > 0) {
                    return t('editor_info.attachment_message');
                }
            } else {
                return cleanedMessage;
            }
        }
    }

    return <MiniChat 
        showChat={showChat}
        renderMessages={props.renderMessages}
        messagesEndRef={props.messagesEndRef}
        botData={props.botData}
        handleClickSendMessage={props.handleClickSendMessage}
        enterClick={props.enterClick}
        inputRef={props.inputRef}
        t={t}
        fromCx={props.fromCx}
        hasBot={props.hasBot}
        noBot={props.noBot}
        previewImageURL={props.previewImageURL} 
        nameColor={props.nameColor} 
        headerColor={props.headerColor} 
        backgroundColor={props.backgroundColor} 
        consumerMessageColor={props.consumerMessageColor} 
        agentColor={props.agentColor} 
        sendButtonColor={props.sendButtonColor}
        handleChatToggle={handleChatToggle}
        isAnimating={isAnimating}
        formatReplyMessage={formatReplyMessage}
        replyMessage={replyMessage}
        dispatch={dispatch}

    />
}

export default MiniChatController;