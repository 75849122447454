import { Button, Col, Row } from "react-bootstrap";

import styles from "./styles.module.css";
import MiniChatController from "./miniChat/indexController";
import Utils from "../../../../../core/shared/utils";
import { IconPaperclip, IconX } from "@tabler/icons-react";
import { setReplyMessage } from "../../../../../store/internal";

/**
 * Iframe do chat de bot funcionando de forma similar a uma home do sistema
 * @returns 
 */
const ChatBotIFrame = ({
  t,
  botData,
  hasBot,
  renderMessages,
  messagesEndRef,
  inputRef,
  enterClick,
  handleClickSendMessage,
  fromCx,
  behavior,
  previewImageURL,
  nameColor, 
  headerColor, 
  backgroundColor,
  consumerMessageColor, 
  agentColor, 
  sendButtonColor,
  formatReplyMessage,
  dispatch,
  replyMessage
}) => {
  const noBot = () => {
    return (
      <>
        <Row className="justify-content-center mx-0" style={{ marginTop: '2vh', textAlign: 'center', font: 'normal normal bold calc(0.2em + 8vw) Montserrat', letterSpacing: '0px', color: '#1E1E1E', opacity: '1' }}>
          404
        </Row>
        <Row className="justify-content-center mx-0">
          <div style={{ textAlign: 'center', font: 'normal normal bold calc(0.2em + 1vw) Montserrat', letterSpacing: '0px', color: '#1E1E1E', opacity: '1' }}>
              {t("configurations.chatbots.view.iframe.without_results")}
          </div>
        </Row>
        <Row className="justify-content-center mx-0">
          <Col sm={8} md={8} style={{ textAlign: 'center', font: 'normal normal normal calc(0.3em + 0.6vw) Montserrat', letterSpacing: '0px', color: '#1E1E1E', opacity: '1' }}>
              {t("configurations.chatbots.view.iframe.no_bot")}
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <>{/* Container */}
        <Row className="nopadding" /*style={{ height: '100vh' }}*/>
          <Col className="nopadding" >
            {behavior === "floating"?
              <MiniChatController
                renderMessages={renderMessages}
                messagesEndRef={messagesEndRef}
                botData={botData}
                handleClickSendMessage={handleClickSendMessage}
                enterClick={enterClick}
                inputRef={inputRef}
                t={t}
                fromCx={fromCx} 
                hasBot={hasBot} 
                noBot={noBot}  
                previewImageURL={previewImageURL}    
                nameColor={nameColor} 
                headerColor={headerColor} 
                backgroundColor={backgroundColor} 
                consumerMessageColor={consumerMessageColor} 
                agentColor={agentColor} 
                sendButtonColor={sendButtonColor}         
              />:
              <>
                <Row style={{ backgroundColor: headerColor || '#1E1E1E', borderRadius: '3.2px', color: '#FFFFFF', font: 'var(--px15_19_Bold-font)' }}
                  className="d-flex align-items-center justify-content-start mx-1">
                  <Col className="" style={{ paddingLeft: '0px', color: nameColor || "#fff" }}>
                    {previewImageURL && <img src={previewImageURL} className="my-1 mx-1 me-2" style={{ backgroundColor: '#fff', borderRadius: '50%', padding: '2px' }} alt="" width={'24px'} height={"24px"} />}
                    {botData !== null &&
                      botData.bot_name
                    }
                  </Col>
                </Row>
                <Row id="#chat" className="d-flex align-items-center justify-content-start mx-1" style={{
                  height: '75vh',
                  backgroundColor: backgroundColor || '#f9f9f9',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  borderRadius: '5px'
                }} >
                  {hasBot?
                    <>
                      <div className="chat-wrapper" style={{ minHeight: '100%' }}>
                        {renderMessages()}
                      </div>
                      <div ref={messagesEndRef} />
                    </>
                    :
                    <>
                      {noBot()}
                    </>
                  }
                </Row>
                {botData !== null ?
                  <>
                    {(replyMessage !== null && replyMessage !== undefined) &&
                      <Row className="justify-content-start nopadding">
                        <div className={`chat-editor-component  ${true}`} style={{ paddingBottom: "1.5rem" }}>
                          <div className="chat-editor-reply-info" style={{ paddingTop: "2rem", paddingBottom: "2rem" }}>
                            <div className="chat-editor-reply-message">
                              <span className="chat-editor-reply-message-author">
                                {replyMessage?.user_name ? replyMessage?.user_name : botData?.bot_name}
                              </span>
                              <span className="chat-editor-reply-message-content">
                                {replyMessage?.attachments?.length > 0 &&
                                  <IconPaperclip />
                                }
                                {formatReplyMessage(replyMessage.content, replyMessage)}
                              </span>
                            </div>
                            <span className="chat-editor-reply-cancel">
                              <IconX onClick={() => dispatch(setReplyMessage(null))} />
                            </span>
                          </div>
                        </div>
                      </Row>
                    }
                    <Row className="justify-content-start py-2 mt-0 mx-1 nopadding" onClick={() => { inputRef.current.focus() }} style={{
                      border: `2px solid ${headerColor || '#1E1E1E'}`,
                      backgroundColor: '#f8f9fa',
                      borderRadius: '4px',
                      font: 'normal normal bold calc(0.2em + 0.7vw) Roboto',
                      color: '#707070',
                      display: 'flex',
                    }}>
                      <Col className={"nopadding"}
                        style={{
                          backgroundColor: '#F9F9F9',
                          width: `100%`,
                          borderRadius: '3px',
                        }}
                      >
                        <Row className={" nopadding me-1"} style={{ paddingLeft: '0 !important' }}>
                          <textarea className={styles.editor + " nopadding"} onKeyUp={enterClick} ref={inputRef} style={{ border: 'none', background: 'transparent' }}></textarea>
                        </Row>
                        <Row className="justify-content-end nopadding me-1">
                          <Button id="sendButtonMessage" type="submit" onClick={handleClickSendMessage} className={styles.buttonBlueSendMessage + " d-flex justify-content-center align-items-center"} style={{ width: '60px', backgroundColor: sendButtonColor, color: Utils.getTextColorForBackground(sendButtonColor) }} >{t("configurations.chatbots.view.iframe.send")}</Button>
                        </Row>
                      </Col>
                    </Row>
                  </>
                  :
                  <></>
                }
              </>
            }
          </Col>
        </Row>
      </>
    </>
  );
}

export default ChatBotIFrame;