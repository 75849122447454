import { useEffect, useState } from 'react'
import { BodyInterface } from './indexModel';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NewPassword from '.';
import { AppRequesterController } from '../../services/appRequester/appRequesterController';

const NewPasswordController = (props) => {
    const AppRequesterConst = new AppRequesterController();

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const [/*isLoading*/, setIsLoading] = useState(false)
    const [/*user_password*/, Setuser_password] = useState(null as string || null);
    const [/*user_password2*/, Setuser_password2] = useState(null as string || null);
    const [/*showfeedback*/, setShowfeedback] = useState(false);
    const [validated] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [showfeedbackPassword, setShowfeedbackPassword] = useState(false);
    const [showfeedbackPassword2, setShowfeedbackPassword2] = useState(false);
    const [token, setToken] = useState("");
    const [user_name, setUser_name] = useState("");
    const { t } = useTranslation();


    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const code = urlParams.get('code');
        const name = urlParams.get('name');

        if (code && name) {
            setToken(code);
            //variavel para verificar se o name vem em base64
            var base64 = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{4}|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)$/;
            base64.test(name) ? setUser_name(base64ToBytes(name)) : setUser_name(name.replace("_", " "));
        } else {
            navigate("/");
        }
    }, [token, navigate]);

    function base64ToBytes(base64) {
        const decodificado = atob(base64);
        return decodificado
    }


    const ValidateNewPassword = (values: BodyInterface) => {
        let errors = {};

        if (!values.password) {
            errors['password'] = t('new_password.validate.fill_field');

            setShowfeedbackPassword(true);
        }
        else if (values.password.length > 0 && values.password.length <= 4) {
            errors['password'] = t('new_password.validate.password_min_length');
            setShowfeedbackPassword(true);
        } else if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(values.password)) {
            errors['password'] = t('new_password.validate.password_special_char');
            setShowfeedbackPassword(true);
        } else if (!/[A-Z]/.test(values.password)) {
            errors['password'] = t('new_password.validate.password_uppercase');
            setShowfeedbackPassword(true);
        } else if (!/[a-z]/.test(values.password)) {
            errors['password'] = t('new_password.validate.password_lowercase');
            setShowfeedbackPassword(true);
        } else if (!/\d/.test(values.password)) {
            errors['password'] = t('new_password.validate.password_number');
            setShowfeedbackPassword(true);
        }
        else {
            setShowfeedbackPassword(false);
        }

        if (!values.password2) {
            errors['password2'] = t('new_password.validate.fill_field');
            setShowfeedbackPassword2(true);

        } else if (values.password !== values.password2) {
            errors['password2'] = t('new_password.validate.password_not_match');
            setShowfeedbackPassword2(true);
        }
        else {
            setShowfeedbackPassword2(false);
        }
        return errors;
    }

    const clickPassword = () => {
        if ((document.getElementById("user_password") as HTMLInputElement).type === "text") {
            (document.getElementById("user_password") as HTMLInputElement).type = "password";
        } else {
            (document.getElementById("user_password") as HTMLInputElement).type = "text";
        }

        setShowPassword1(!showPassword1);
    };

    const clickPassword2 = () => {
        if ((document.getElementById("user_password2") as HTMLInputElement).type === "text") {
            (document.getElementById("user_password2") as HTMLInputElement).type = "password";
        } else {
            (document.getElementById("user_password2") as HTMLInputElement).type = "text";
        }

        setShowPassword2(!showPassword2);
    };

    return <NewPassword
        user_name={user_name}
        t={t}
        Setuser_password={Setuser_password}
        Setuser_password2={Setuser_password2}
        ValidateNewPassword={ValidateNewPassword}
        token={token}
        AppRequesterConst={AppRequesterConst}
        setShowfeedbackPassword2={setShowfeedbackPassword2}
        setShowfeedback={setShowfeedback}
        dispatch={dispatch}
        navigate={navigate}
        setIsLoading={setIsLoading}
        validated={validated}
        showPassword1={showPassword1}
        showfeedbackPassword={showfeedbackPassword}
        clickPassword={clickPassword}
        showfeedbackPassword2={showfeedbackPassword2}
        showPassword2={showPassword2}
        clickPassword2={clickPassword2}
    />
}

export default NewPasswordController